import Success from '@oneflare/flarekit/lib/components/icons/Success';
import { styled } from 'styled-components';

export const AdditionInfoStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

export const SuccessStyled = styled(Success).attrs(({ theme }) => ({
  fill: theme.color.successDark,
  kind: 'solid',
  width: '20px',
  height: '20px'
}))`
  margin-right: 16px;
  position: relative;
  top: 2px;
`;

export const AdditionLabelStyled = styled.span`
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.gunMetal};
`;
