import { memo } from 'react';

import {
  OneflareStyled,
  FacebookStyled,
  GoogleStyled
} from './styled/Logos';

type LogoProps = {
  logo: string;
  isAbsolute: boolean;
};

const Logos = ({ logo, isAbsolute }: LogoProps) => {
  const logoObj = {
    oneflare: <OneflareStyled $isAbsolute={isAbsolute} kind="logo" />,
    of: <OneflareStyled $isAbsolute={isAbsolute} kind="logo" />,
    facebook: <FacebookStyled $isAbsolute={isAbsolute} />,
    google: <GoogleStyled $isAbsolute={isAbsolute} kind="G" />
  };
  return (
    logoObj[logo.toLowerCase()]
  );
};

export default memo(Logos);
