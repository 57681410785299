import { FC, memo } from 'react';

import { BusinessPublicProfileModel } from 'types/oneflare.com.au/businessPublicProfile';

import {
  SubSectionWrapperStyled,
  TitleStyled
} from '../../styled/BppHeader';

import AdditionalInfo from './components/AdditionalInfo';
import OpeningHoursItem from './components/OpeningHoursItem';
import {
  OpeningHoursStyled
} from './styled';


type Props = {
  business: BusinessPublicProfileModel;
}

const OpeningHours: FC<Props> = ({ business }: Props) => {
  const openingHours = business?.openingHours || [];
  const available247 = business?.available247;
  const openPublicHolidays = business?.openPublicHolidays;

  if (!openingHours.length) return null;

  return (
    <SubSectionWrapperStyled>
      <TitleStyled>Opening hours</TitleStyled>
      <OpeningHoursStyled>
        {
          openingHours.map((item) => (
            <OpeningHoursItem
              key={item.day}
              item={item}
              available247={available247}
            />
          ))
        }
      </OpeningHoursStyled>
      <AdditionalInfo openPublicHolidays={openPublicHolidays} />
    </SubSectionWrapperStyled>
  );
};

export default memo(OpeningHours);
