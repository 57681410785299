import H1 from '@oneflare/flarekit/lib/components/H1';
import H2 from '@oneflare/flarekit/lib/components/H2';
import H3 from '@oneflare/flarekit/lib/components/H3';
import H4 from '@oneflare/flarekit/lib/components/H4';
import H5 from '@oneflare/flarekit/lib/components/H5';
import H6 from '@oneflare/flarekit/lib/components/H6';
import VerifiedBusiness from '@oneflare/flarekit/lib/components/icons/VerifiedBusiness';
import { styled, css } from 'styled-components';

const combinedHeadingStyles = css`
  word-break: break-word;
`;

// #region - Styled headings of all 6 levels
export const H1Styled = styled(H1)`
  ${combinedHeadingStyles}
`;

export const H2Styled = styled(H2)`
  ${combinedHeadingStyles}
`;

export const H3Styled = styled(H3)`
  ${combinedHeadingStyles}
`;

export const H4Styled = styled(H4)`
  ${combinedHeadingStyles}
`;

export const H5Styled = styled(H5)`
  ${combinedHeadingStyles}
`;

export const H6Styled = styled(H6)`
  ${combinedHeadingStyles}
`;
// #endregion

export const SpanStyled = styled.span`
  white-space: nowrap;
`;

export const VerifiedBusinessStyled = styled(VerifiedBusiness).attrs(({ iconStyles }) => ({
  width: iconStyles.width,
  height: iconStyles.height
}))`
  position: relative;
  top: ${({ iconStyles }) => iconStyles.top};
`;
