import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import Facebook from '@oneflare/flarekit/lib/components/logos/Facebook';
import Instagram from '@oneflare/flarekit/lib/components/logos/Instagram';
import LinkedIn from '@oneflare/flarekit/lib/components/logos/LinkedIn';
import Pinterest from '@oneflare/flarekit/lib/components/logos/Pinterest';
import Twitter from '@oneflare/flarekit/lib/components/logos/Twitter';
import YouTube from '@oneflare/flarekit/lib/components/logos/YouTube';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const SocialMediaWrapperStyled = styled.section`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.lgUp`
    margin-bottom: 32px;
  `}
`;

export const AnchorStyled = styled(Anchor)`
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  min-width: 24px;
`;

const socialMediaIconAttrs = {
  width: 24,
  height: 24
};

export const InstagramStyled = styled(Instagram).attrs(socialMediaIconAttrs)``;

export const FacebookStyled = styled(Facebook).attrs(socialMediaIconAttrs)``;

export const LinkedInStyled = styled(LinkedIn).attrs(socialMediaIconAttrs)``;

export const TwitterStyled = styled(Twitter).attrs(socialMediaIconAttrs)``;

export const PinterestStyled = styled(Pinterest).attrs(socialMediaIconAttrs)``;

export const YouTubeStyled = styled(YouTube).attrs(socialMediaIconAttrs)``;
