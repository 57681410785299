import Tag from '@oneflare/flarekit/lib/components/Tag';
import ToggleContent from '@oneflare/flarekit/lib/components/ToggleContent';
import isEmpty from 'lodash/isEmpty';
import { FC, memo } from 'react';

import { ServiceType } from 'types/oneflare.com.au/businessPublicProfile';

import { SubSectionWrapperStyled, TitleStyled } from '../../styled/BppHeader';

import {
  ServicesListStyled,
  ServiceItemStyled,
  AnchorStyled
} from './styled/Services';

type Props = {
  serviceTypes: Array<ServiceType>;
};

const Services: FC<Props> = ({ serviceTypes }: Props) => (
  isEmpty(serviceTypes) ? null : (
    <SubSectionWrapperStyled>
      <TitleStyled>Services</TitleStyled>
      <ToggleContent
        collapseLabel="View less"
        showLabel="View more"
        collapsedHeight={156}
      >
        <ServicesListStyled>
          {serviceTypes.map(({ id, name, primaryCategory }) => (
            <ServiceItemStyled key={id}>
              <Tag>
                <AnchorStyled href={`/${primaryCategory?.slug}`} target="_blank">
                  {name}
                </AnchorStyled>
              </Tag>
            </ServiceItemStyled>
          ))}
        </ServicesListStyled>
      </ToggleContent>
    </SubSectionWrapperStyled>
  )
);

export default memo(Services);
