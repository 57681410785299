import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import TruncateText from '@oneflare/flarekit/lib/components/TruncateText';
import Image from 'next/image';
import { FC, memo, useMemo } from 'react';

import { compareDate, BusinessProfileTrackerModel } from 'pages/oneflare.com.au/BusinessPublicProfile/utils';
import { friendlyDate } from 'shared/utils/helpers';

import Logos from '../../../ReviewsLogos';
import ReplyToReview from '../ReplyToReview';

import {
  ReviewHeaderWrapperStyled,
  AvatarStyled,
  ColumnWrapperStyled,
  ReviewerTagWrapperStyled,
  ReviewerStyled,
  TagStyled,
  StarRatingStyled,
  OriginStyled,
  DateStyled,
  FlexWrapperStyled,
  SuccessStyled,
  ParagraphStyled,
  CommentWrapperStyled,
  CommentFlexWrapperStyled,
  CommenterStyled,
  TruncateTextStyled,
  CommenterTruncateTextStyled,
  CommenterNameStyled,
  JobInfoStyled
} from './styled/Review';

type Props = {
  avatarUrl: string;
  businessName: string;
  comment?: {
    message: string | null;
    postedAt: string | null;
  };
  id: number;
  index?: number;
  isReviewModal?: boolean;
  isSameBusiness?: boolean;
  jobTitle: string;
  location: string;
  origin: string;
  postedAt: string;
  rating: number;
  reviewerName: string;
  reviewText: string;
  reviewTruncateLines?: number;
  tracker?: BusinessProfileTrackerModel;
  updateReply?: (index: number) => void;
  verified: boolean;
};

const Review: FC<Props> = ({
  avatarUrl,
  businessName,
  comment,
  id,
  index,
  isReviewModal,
  isSameBusiness,
  jobTitle,
  location,
  origin,
  postedAt,
  rating,
  reviewerName,
  reviewText,
  reviewTruncateLines,
  tracker,
  updateReply,
  verified
}) => {
  const reviewTextRendered = useMemo(
    () => (isReviewModal ? (
      <Paragraph>{reviewText}</Paragraph>
    ) : (
      <TruncateTextStyled
        lines={reviewTruncateLines}
        expandable
        text={reviewText}
        onExpand={() => tracker.interaction('reviewReadMore', { value: id })}
      />
    )),
    [id, isReviewModal, reviewText, reviewTruncateLines, tracker]
  );

  return (
    <>
      <ReviewHeaderWrapperStyled>
        <AvatarStyled kind="circle" imageUrl={avatarUrl}>
          <Image
            alt="Avatar"
            src={avatarUrl}
            fill
            priority
          />
        </AvatarStyled>
        <Logos isAbsolute logo={origin} />
        <ColumnWrapperStyled $grow>
          <ReviewerTagWrapperStyled>
            <ReviewerStyled>{reviewerName}</ReviewerStyled>
            {compareDate(postedAt) < 30 ? <TagStyled>NEW</TagStyled> : null}
          </ReviewerTagWrapperStyled>
          <StarRatingStyled rating={rating} />
          <OriginStyled>
            {`${verified ? 'Verified ' : ''}${
              origin === 'OF' ? 'Oneflare' : origin
            } review`}
          </OriginStyled>
        </ColumnWrapperStyled>
        <ColumnWrapperStyled>
          <DateStyled>{friendlyDate(postedAt)}</DateStyled>
          {verified && (
            <FlexWrapperStyled>
              <SuccessStyled />
              <ParagraphStyled>Verified</ParagraphStyled>
            </FlexWrapperStyled>
          )}
        </ColumnWrapperStyled>
      </ReviewHeaderWrapperStyled>
      {jobTitle && (
        <JobInfoStyled>
          <strong>Job Type: </strong>
          {jobTitle}
        </JobInfoStyled>
      )}
      {location && (
        <JobInfoStyled>
          <strong>Location: </strong>
          {location}
        </JobInfoStyled>
      )}
      {reviewText ? (
        reviewTextRendered
      ) : (
        <Paragraph>
          {`${reviewerName} left a ${rating} star review for ${businessName}`}
        </Paragraph>
      )}
      {!isReviewModal && comment?.message ? (
        <CommentWrapperStyled>
          <CommentFlexWrapperStyled>
            <CommenterStyled>
              <CommenterTruncateTextStyled lines={1}>
                <CommenterNameStyled>{businessName}</CommenterNameStyled>
              </CommenterTruncateTextStyled>
              {' replied'}
            </CommenterStyled>
            <DateStyled>{friendlyDate(comment.postedAt)}</DateStyled>
          </CommentFlexWrapperStyled>
          <TruncateText
            lines={reviewTruncateLines}
            expandable
            text={comment.message}
          />
        </CommentWrapperStyled>
      ) : (
        isSameBusiness && (
          <ReplyToReview
            feedbackId={id}
            index={index}
            origin={origin}
            updateReply={updateReply}
          />
        )
      )}
    </>
  );
};

Review.defaultProps = {
  comment: null,
  index: 0,
  isReviewModal: false,
  isSameBusiness: false,
  reviewTruncateLines: 3,
  tracker: null,
  updateReply: () => {}
};

export default memo(Review);
