import Button from '@oneflare/flarekit/lib/components/Button';
import H2 from '@oneflare/flarekit/lib/components/H2';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import TruncateText from '@oneflare/flarekit/lib/components/TruncateText';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled, css } from 'styled-components';

export const BppHeaderWrapperStyled = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 20px;

  ${media.lgUp`
    padding: 0 10px 0 0;
  `}
`;

export const TruncateCopyStyled = styled(TruncateText)<{
  $isNewline?: boolean;
}>`
  font-family: ${({ theme }) => theme.font.primaryRegular};
  color: ${({ theme }) => theme.color.gunMetal};
  font-size: 16px;
  ${({ $isNewline }) => $isNewline && css`
    white-space: pre-line;
  `}
`;

export const TitleStyled = styled(H2)<{
  $centerTitle?: boolean;
}>`
  text-align: ${({ $centerTitle }) => ($centerTitle ? 'center' : 'left')};
  margin-block-end: 8px;
  font-size: 16px;

  ${media.lgUp`
    text-align: left;
  `}
`;

export const SectionWrapperStyled = styled.section`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  padding: 40px 0;
  align-items: flex-start;
  word-break: break-word;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.cloud};

  ${media.lgUp`
    border-top: none;
    padding: 0 0 32px;
  `}
`;

export const SubSectionWrapperStyled = styled(SectionWrapperStyled)`
  border-top: none;
  padding: 0 0 32px;
`;

export const RowWrapperStyled = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block-start: 8px;
  width: inherit;
`;

export const DetailCopyStyled = styled(Paragraph)`
  max-width: 100%;
  min-width: 0;
  flex: 1;

  strong {
    margin-inline-end: 4px;
  }
`;

export const DetailLinkStyled = styled.span`
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 1px);
  font-family: ${({ theme }) => theme.font.primaryRegular};
  color: ${({ theme }) => theme.color.gunMetal};
  font-size: 16px;
  min-width: 106px;

  strong {
    margin-inline-end: 4px;
  }

  a {
    max-width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    position: relative;

    ${media.smUp`
      max-width: 100%;
    `}

    ${media.mdUp`
      max-width: 230px;
    `}
  }
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
`;
