import { useMutation } from '@apollo/client';
import truncate from 'lodash/truncate';
import { useCallback, useEffect, useMemo } from 'react';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { TRACK_BUSINESS } from 'queries/oneflare.com.au/businessPublicProfile';
import { squish } from 'shared/utils/helpers';
import type {
  BusinessPublicProfileModel,
  BusinessPublicProfileReviewModel,
  TrackBusinessVariablesModel
} from 'types/oneflare.com.au/businessPublicProfile';

type MetaBuilderProps = {
  business?: BusinessPublicProfileModel;
  getReviews: BusinessPublicProfileReviewModel
};

export const useBuildMetaDescription = ({
  business,
  getReviews
}: MetaBuilderProps): { metaDescription: string } => {
  const metaDescription: string = useMemo(() => {
    const {
      description,
      name: businessName = '',
      state,
      suburb,
      serviceTypes = [],
      gallery: portfolio = [],
      servicingZoneNames: regions = [],
      primaryCategory
    } = business || {};

    const {
      name: category
    } = primaryCategory || {};

    const {
      reviewCount = 0
    } = getReviews || {};

    const serviceTypesText = serviceTypes.length > 1 ? ` offering ${serviceTypes.length} types of services,` : '';
    const regionsText = (regions.length > 1 || regions[0]?.includes('all of')) ? ' serving multiple regions' : '';
    const reviewsText = reviewCount > 1 ? ', reviews' : '';
    const portfolioText = portfolio.length > 1 ? ', portfolio' : '';

    // Return the first 150 characters of a business description
    // if suburb / state / category is unavailable
    if (suburb && state && category) {
      return `${businessName} is a ${category}${serviceTypesText} located in ${suburb} ${state}${regionsText}. View profile${reviewsText}${portfolioText} and enquire now.`;
    }
    return truncate(squish(description), { length: 150 });
  }, [
    business,
    getReviews
  ]);

  return { metaDescription };
};

export const useTrackBusiness = ({ variables }: { variables: TrackBusinessVariablesModel }) => {
  const [trackBusiness] = useMutation(TRACK_BUSINESS, {
    onError: (error) => {
      DataDogRumAgent.addRumError(error, 'Error tracking business');
    }
  });
  const memoTrack = useCallback(() => {
    trackBusiness({
      variables
    });
  }, [trackBusiness, variables]);

  useEffect(() => {
    memoTrack();
  }, [memoTrack]);
};
