import dynamic from 'next/dynamic';
import { FC, memo, MutableRefObject } from 'react';

import { BusinessProfileTrackerModel } from 'pages/oneflare.com.au/BusinessPublicProfile/utils';
import LazyLoadedSection from 'shared/components/LazyLoadedSection';
import { BusinessPublicProfileModel, BusinessPublicProfileReviewModel } from 'types/oneflare.com.au/businessPublicProfile';

import {
  Qualifications,
  AddReview,
  Reviews,
  Faq,
  UniqueSellingPoints
} from './components';


const Portfolio = dynamic(() => import('./components').then(module => module.Portfolio), { ssr: false });

type Props = {
  business: BusinessPublicProfileModel;
  initialReviewData: BusinessPublicProfileReviewModel;
  isLogin: boolean;
  isSameBusiness: boolean;
  reviewsSectionRef: MutableRefObject<HTMLElement | null>;
  tracker: BusinessProfileTrackerModel;
};

const BppBody: FC<Props> = ({
  business,
  initialReviewData,
  isLogin,
  isSameBusiness,
  reviewsSectionRef,
  tracker
}: Props) => (
  <section>
    <Qualifications qualifications={business.qualifications} tracker={tracker} />
    {!isSameBusiness && !business.reviewedByCurrentUser && (
      <AddReview businessId={business.id} businessName={business.name} isLogin={isLogin} />
    )}
    <Reviews
      businessId={business.id}
      businessName={business.name}
      initialReviewData={initialReviewData}
      isSameBusiness={isSameBusiness}
      reviewsSectionRef={reviewsSectionRef}
      tracker={tracker}
    />
    <LazyLoadedSection autoLoad={false} rootMargin="150px" minHeight={576}>
      <Portfolio gallery={business.gallery} tracker={tracker} />
    </LazyLoadedSection>
    <Faq faqs={business.faqs} />
    <UniqueSellingPoints uniqueSellingPoints={business.uniqueSellingPoints} />
  </section>
);

export default memo(BppBody);
