import Lightbox from '@oneflare/flarekit/lib/components/Lightbox';
import isEmpty from 'lodash/isEmpty';
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { publicRuntimeConfig } from 'lib/utils/Environment';
import { BusinessProfileTrackerModel } from 'pages/oneflare.com.au/BusinessPublicProfile/utils';
import { useCloudFrontImageResizeURL } from 'shared/utils/hooks';
import { PortfolioImage } from 'types/oneflare.com.au/businessPublicProfile';

import { SectionStyled, TitleStyled } from '../../styled/BppBody';

type Props = {
  gallery: Array<PortfolioImage>;
  tracker: BusinessProfileTrackerModel;
};

const Portfolio: FC<Props> = ({ gallery, tracker } : Props) => {
  const [localImages, setLocalImages] = useState(gallery?.slice(0, 10));
  // Whether user has started interacting with Portfolio
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (isTouched) setLocalImages(gallery);
  }, [gallery, isTouched]);

  const [getCloudFrontImage] = useCloudFrontImageResizeURL({ defaultBucket: 'oneflare-production' });
  const getResizedImage = useCallback((
    id: number,
    filename: string,
    originalUrl: string,
    width: number,
    height: number,
    fit: string
    ) => (
      // return original image if we are in local development
      publicRuntimeConfig.ENVIRONMENT === 'development' || filename == null || typeof filename === 'undefined'
      ? originalUrl
      : getCloudFrontImage({
      key: `attachments/${id}/original/${filename}`,
      dynamicEdits: {
        resize: {
          width,
          height,
          fit
        }
      }
    })
  ), [getCloudFrontImage]);

  const images = useMemo(() => localImages?.map(({
    filename,
    id,
    name,
    original,
    thumb
  }) => ({
    caption: name,
    id,
    original: getResizedImage(id, filename, original, 800, 500, 'inside'),
    thumb: getResizedImage(id, filename, thumb, 118, 118, 'cover')
  })), [localImages, getResizedImage]);

  const triggerIsTouched = () => {
    if (!isTouched) setIsTouched(true);
  };

  return isEmpty(gallery) ? null : (
    <SectionStyled
      $portfolio
      onMouseDown={triggerIsTouched} // Detect interaction on regular desktop.
      onTouchStart={triggerIsTouched} // Detect interaction on touch-sensitive surface.
    >
      <TitleStyled $withMargin>{`Portfolio (${gallery.length})`}</TitleStyled>
      <Lightbox
        kind="inline"
        images={images}
        showCaption={false}
        onClickPrev={() => tracker.interaction('portfolio', { action: 'click_button_prev', value: 1 })}
        onClickNext={() => tracker.interaction('portfolio', { action: 'click_button_next', value: 2 })}
        onClickThumbnail={(imageId: number) => tracker.interaction('portfolio', { action: 'click_cta', value: imageId })}
      />
    </SectionStyled>
  );
};

export default memo(Portfolio);
