import Crown from '@oneflare/flarekit/lib/components/icons/Crown';
import MostHired from '@oneflare/flarekit/lib/components/logos/OneflareAwards/MostHired';
import TopRated from '@oneflare/flarekit/lib/components/logos/OneflareAwards/TopRated';
import OnTime from '@oneflare/flarekit/lib/components/logos/OneflareBadges/OnTime';
import { styled, css } from 'styled-components';

export const RowWrapperStyled = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-block-end: 20px;
  width: inherit;
  height: 100%;

  &:last-of-type {
    margin-block-end: 0;
  }
`;

const logoAttrs = {
  width: '22px',
  height: '24px'
};

const logoStyles = css`
  margin-inline-end: 14px;
  height: 24px;
  vertical-align: middle;
`;

export const MostHiredStyled = styled(MostHired).attrs(() => ({
  ...logoAttrs,
  kind: 'variant'
}))`
  ${logoStyles}
`;

export const TopRatedStyled = styled(TopRated).attrs(() => ({
  ...logoAttrs,
  kind: 'variant'
}))`
  ${logoStyles}
`;

export const OnTimeStyled = styled(OnTime).attrs(() => ({
  ...logoAttrs
}))`
  margin-block-start: 4px;
  ${logoStyles}
`;

export const CrownWrapperStyled = styled.div`
  padding: 2px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.charcoal};
  display: flex;
  justify-content: center;
  margin-inline-end: 14px;
  align-items: center;
`;

export const CrownStyled = styled(Crown).attrs(({ theme }) => ({
  width: '16',
  height: '10',
  fill: 'tertiaryDark' in theme.color && theme.color.tertiaryDark
}))`
  width: 16px;
  height: 100%;
  vertical-align: middle;
`;
