import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import isEmpty from 'lodash/isEmpty';
import { FC, memo } from 'react';

import { BusinessProfileTrackerModel } from 'pages/oneflare.com.au/BusinessPublicProfile/utils';
import { formatAbnNumber } from 'shared/utils/helpers';
import { BusinessQualificationModel } from 'types/oneflare.com.au/businessPublicProfile';

import { ReactTooltipStyled } from '../../../../styled/BusinessPublicProfile';
import { SectionStyled, TitleStyled } from '../../styled/BppBody';

import {
  ParagraphStyled,
  ToggleContentStyled,
  QualificationsListStyled,
  QualificationItemStyled,
  QualificationDetailsStyled,
  QualificationStatusStyled,
  SuccessStyled
} from './styled/Qualifications';
import { renderQualificationIcon } from './utils';

type Props = {
  qualifications: Array<BusinessQualificationModel>;
  tracker: BusinessProfileTrackerModel;
};

const Qualifications: FC<Props> = ({ qualifications, tracker }: Props) => {
  return isEmpty(qualifications) ? null : (
    <SectionStyled>
      <TitleStyled>Qualifications</TitleStyled>
      <ParagraphStyled>
        To meet our high standards, businesses are requested to provide as much company information as possible (such as ABN details and any relevant industry qualifications). Oneflare has been provided with the following details for this business:
      </ParagraphStyled>
      <ToggleContentStyled
        collapseLabel="View less"
        showLabel="View all"
        collapsedHeight={144}
      >
        <QualificationsListStyled>
          {qualifications.map(({ name, number, type }) => type !== 'on_time_guarantee' && (
            <QualificationItemStyled key={name + number}>
              <QualificationDetailsStyled>
                {renderQualificationIcon(type)}
                <Paragraph>
                  <strong>{name}</strong>
                  {number && ` - ${number}`}
                </Paragraph>
              </QualificationDetailsStyled>
              <QualificationStatusStyled>
                {name === 'ABN' ? (
                  <>
                    <Anchor
                      href={`https://abr.business.gov.au/ABN/View/${number}`}
                      rel="noreferrer noopener"
                      target="_blank"
                      data-tooltip-content="Check this ABN"
                      data-tooltip-id="qualifications-abn-tooltip"
                      onClick={() => tracker.interaction('abn')}
                    >
                      {formatAbnNumber(number)}
                    </Anchor>
                    <ReactTooltipStyled id="qualifications-abn-tooltip" place="right" />
                  </>
                ) : (
                  <>
                    <SuccessStyled />
                  </>
                )}
              </QualificationStatusStyled>
            </QualificationItemStyled>
          ))}
        </QualificationsListStyled>
      </ToggleContentStyled>
      <ParagraphStyled>
        Reminder: If your job requires a qualified or licensed tradesperson, always request details regarding any relevant professional licences before work has commenced and check those details on relevant industry databases to ensure they remain valid.
      </ParagraphStyled>
    </SectionStyled>
  );
};

export default memo(Qualifications);
