import media from '@oneflare/flarekit/lib/libs/media';
import { styled, ExecutionContext } from 'styled-components';

export const GetFreeQuotesWrapperStyled = styled.div<{
  $isStickyHeader?: boolean;
}>`
  background-color: ${({ theme }) => theme.color.tin};
  border-radius: 8px;
  padding: 24px;

  ${media.mdUp`
    padding: 24px 114px 32px;
  `}

  ${media.lgUp`
    padding: 24px;
    position: sticky;
    top: ${({ $isStickyHeader }: ExecutionContext & {
      $isStickyHeader?: boolean;
    }) => ($isStickyHeader ? '88px' : '32px')};
  `}
`;
