import ButtonLink from '@oneflare/flarekit/lib/components/ButtonLink';
import Star from '@oneflare/flarekit/lib/components/icons/Star';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import Tag from '@oneflare/flarekit/lib/components/Tag';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled, keyframes } from 'styled-components';

import StarRating from 'shared/components/StarRating';

import { RowWrapperStyled } from '../../../styled/BppHeader';

export const SectionStyled = styled.section`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.lgUp`
    margin-bottom: 32px;
  `}
`;

export const StarRatingStyled = styled(StarRating)`
  margin-right: 8px;

  .star {
    height: 18px;
    width: 18px;
  }
`;

export const TagWrapperStyled = styled(RowWrapperStyled)`
  margin-block-start: 0;
`;

export const HiredCountStyled = styled(Paragraph)`
  margin-top: 8px;
  text-align: center;
`;

export const DetailTitleStyled = styled(Paragraph)`
  margin-right: 4px;
  font-weight: bold;
`;

export const NewTagStyled = styled(Tag)`
  margin: 12px 0;
  border-radius: 18px;
`;

export const FeaturedTagStyled = styled(Tag)`
  margin: 16px 0 0;
  font-size: 16px;
`;

export const StarStyled = styled(Star).attrs(({ theme }) => ({
  width: '12px',
  height: '100%',
  kind: 'solid',
  fillStar: theme.color.white
}))`
  width: 16px;
  height: 100%;
  align-self: flex-end;
  margin-inline-end: 4px;
`;

export const CtaStyled = styled(ButtonLink)`
  width: 100%;

  ${media.mdUp`
    margin-top: 16px;
  `}
`;

const slidein = keyframes`
  from {
    bottom: -80px;
  }

  to {
    bottom: 0;
  }
`;

export const CtaDesktopWrapperStyled = styled.div`
  padding: 0;
  margin: 0;
  display: none;
  width: 100%;

  ${media.mdUp`
    display: block;
  `};
`;

export const CtaMobileWrapperStyled = styled.div`
  width: 100%;
  padding: 16px 20px;
  border-top: 1px solid ${({ theme }) => theme.color.cloud};
  background-color: ${({ theme }) => theme.color.white};
  position: fixed;
  bottom: -80px;
  z-index: ${({ theme: { zIndex } }) => zIndex[1]};
  animation: .5s .5s both ${slidein};

  ${media.mdUp`
    display: none;
  `}
`;
