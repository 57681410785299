import { memo } from 'react';

import { OpeningHoursModel } from 'types/oneflare.com.au/businessPublicProfile';

import {
  OpeningHoursListStyled,
  OpeningHoursDayStyled,
  ShiftWrapperStyled,
  ShiftStyled
} from './styled';

type OpeningHoursItemProps = {
  item: OpeningHoursModel;
  available247: boolean;
};

const dayMap = {
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
  Sun: 'Sunday'
};

const RenderFormattedTime = ({
  day,
  formattedOpenHours,
  formattedOpenHoursLength
}: {
  formattedOpenHours: Array<Array<string>>;
  formattedOpenHoursLength: number;
  day: string;
}) => {
  return (
    <>
      {formattedOpenHours.map((time, index) => (
        <ShiftStyled key={`${day}${time}`}>
          {time.join(' – ')}
          {index === 0 && formattedOpenHoursLength === 2 && ','}
        </ShiftStyled>
      ))}
    </>
  );
};

const GetTimeShift = ({
  closed,
  formattedOpenHours,
  formattedOpenHoursLength,
  day
}: {
  closed: boolean;
  formattedOpenHours: Array<Array<string>>;
  formattedOpenHoursLength: number;
  day: string;
}) => {
  return (
    <>
      {closed ? (
        <ShiftStyled $closed={closed}>Closed</ShiftStyled>
      ) : (
        <ShiftWrapperStyled>
          {formattedOpenHoursLength ? (
            <RenderFormattedTime
              day={day}
              formattedOpenHours={formattedOpenHours}
              formattedOpenHoursLength={formattedOpenHoursLength}
            />
          ) : (
            <ShiftStyled>9:00 AM – 5:00 PM</ShiftStyled>
          )}
        </ShiftWrapperStyled>
      )}
    </>
  );
};

const OpeningHoursItem = ({
  item: { day, closed, formattedOpenHours },
  available247
}: OpeningHoursItemProps) => {
  const formattedOpenHoursLength = formattedOpenHours.length;
  return (
    <OpeningHoursListStyled>
      <OpeningHoursDayStyled>{`${dayMap[day]}: `}</OpeningHoursDayStyled>
      {available247 ? (
        <ShiftStyled>Open 24 hours</ShiftStyled>
      ) : (
        <GetTimeShift
          closed={closed}
          formattedOpenHours={formattedOpenHours}
          formattedOpenHoursLength={formattedOpenHoursLength}
          day={day}
        />
      )}
    </OpeningHoursListStyled>
  );
};

export default memo(OpeningHoursItem);
