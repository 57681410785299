import H3 from '@oneflare/flarekit/lib/components/H3';
import Cheque from '@oneflare/flarekit/lib/components/icons/Cheque';
import CircleMoney from '@oneflare/flarekit/lib/components/icons/CircleMoney';
import DirectDeposit from '@oneflare/flarekit/lib/components/icons/DirectDeposit';
import Amex from '@oneflare/flarekit/lib/components/logos/AmericanExpress';
import MasterCard from '@oneflare/flarekit/lib/components/logos/MasterCard';
import PayPal from '@oneflare/flarekit/lib/components/logos/PayPal';
import Visa from '@oneflare/flarekit/lib/components/logos/Visa';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import { styled } from 'styled-components';

export const RowWrapperStyled = styled.section`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
  margin: 8px 0 0;
`;

export const AcceptedPaymentsWrapperStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: calc(50% - 16px);
`;

export const PaymentLogoWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 100%;
  border: solid 1px ${({ theme }) => theme.color.cloud};
  height: 48px;
`;

export const TitleStyled = styled(H3)`
  margin-block-end: 16px;
`;

export const LabelStyled = styled(Paragraph)`
  font-size: 14px;
  text-align: center;
  margin-block-start: 4px;
`;

export const AmericanExpressStyled = styled(Amex)`
  height: 22px;
`;

export const PayPalStyled = styled(PayPal)`
  height: 16px;
`;

export const VisaStyled = styled(Visa)`
  height: 14px;
`;

export const MasterCardStyled = styled(MasterCard).attrs(() => ({
  hideText: true
}))`
  height: 36px;
  transform: translateY(4px);
`;

export const CircleMoneyStyled = styled(CircleMoney).attrs(({ theme }) => ({
  fill: theme.color.primary,
  kind: 'hollow',
  width: '22px',
  height: '22px'
}
))`
  width: 22px;
  height: 22px;
`;

export const DirectDepositStyled = styled(DirectDeposit).attrs(({ theme }) => ({
  fill: theme.color.primary,
  width: '24px',
  height: '24px'
}
))`
  width: 24px;
  height: 24px;
`;

export const ChequeStyled = styled(Cheque).attrs(({ theme }) => ({
  fill: theme.color.primary,
  width: '24px',
  height: '24px'
}
))`
  width: 24px;
  height: 24px;
`;
