import { FC, memo, useMemo } from 'react';

import { SocialMediaLinkModel } from 'types/oneflare.com.au/businessPublicProfile';

import * as utils from '../../utils';

import {
  AnchorStyled,
  SocialMediaWrapperStyled
} from './styled/SocialMedia';

type Props = {
  socialMediaLinks: Array<SocialMediaLinkModel>;
}

const SocialMedia: FC<Props> = ({ socialMediaLinks }: Props) => {
  const filteredSocialMediaLinks = useMemo(() => (
    socialMediaLinks?.filter(({ url }) => url)
  ), [socialMediaLinks]);

  return (
    filteredSocialMediaLinks && Boolean(filteredSocialMediaLinks.length) && (
      <SocialMediaWrapperStyled>
        {filteredSocialMediaLinks.map((link) => (
          <AnchorStyled href={utils.buildSocialMediaUrl(link)} key={link.platform}>
            {utils.renderSocialMediaIcon(link.platform)}
          </AnchorStyled>
        ))}
      </SocialMediaWrapperStyled>
    )
  );
};

export default memo(SocialMedia);
