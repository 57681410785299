import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import Avatar from '@oneflare/flarekit/lib/components/Avatar';
import Image from 'next/image';
import pluralize from 'pluralize';
import type { FC } from 'react';
import {
  memo,
  useMemo,
  useCallback,
  MutableRefObject
} from 'react';

import { publicRuntimeConfig } from 'lib/utils/Environment';
import {
  SectionStyled,
  StarRatingStyled,
  TagWrapperStyled,
  HiredCountStyled,
  DetailTitleStyled,
  NewTagStyled,
  FeaturedTagStyled,
  StarStyled,
  CtaStyled,
  CtaMobileWrapperStyled,
  CtaDesktopWrapperStyled
} from 'pages/oneflare.com.au/BusinessPublicProfile/components/BppHeader/components/Profile/styled/Profile';
import { RowWrapperStyled, DetailCopyStyled } from 'pages/oneflare.com.au/BusinessPublicProfile/components/BppHeader/styled/BppHeader';
import { scrollToReviews } from 'pages/oneflare.com.au/BusinessPublicProfile/components/BppHeader/utils';
import { ReactTooltipStyled } from 'pages/oneflare.com.au/BusinessPublicProfile/styled/BusinessPublicProfile';
import type { BusinessProfileTrackerModel } from 'pages/oneflare.com.au/BusinessPublicProfile/utils';
import BusinessNameHeading from 'shared/components/_oneflare/BusinessNameHeading';
import { formatAbnNumber } from 'shared/utils/helpers';
import { useCloudFrontImageResizeURL } from 'shared/utils/hooks';
import type { BusinessPublicProfileModel } from 'types/oneflare.com.au/businessPublicProfile';

const useLogoImageSrc = ({
  business
}: {
  business: BusinessPublicProfileModel;
}): {
  logoImgSrc: string;
} => {
  const [getCloudFrontImage] = useCloudFrontImageResizeURL({
    defaultBucket: 'oneflare-production',
    defaultEdits: {
      resize: {
        width: 165,
        height: 165,
        fit: 'cover'
      }
    }
  });

  const logoImgSrc: string = useMemo(() => {
    if (business.logoId && business.logoFileName) {
      return publicRuntimeConfig.ENVIRONMENT === 'development'
      ? `${publicRuntimeConfig.SITE_URL}/system/avatars/${business.logoId}/profile/${business.logoFileName}`
      : getCloudFrontImage({ key: `avatars/${business.logoId}/original/${business.logoFileName}` });
    }
    return null;
  }, [business.logoFileName, business.logoId, getCloudFrontImage]);

  return { logoImgSrc };
};

const RenderCta = ({
  tracker,
  category_id,
  businessSlug
}: {
  tracker: BusinessProfileTrackerModel;
  category_id: string | number;
  businessSlug: string;
}) => {
  const buttonProps = {
    link: `${publicRuntimeConfig.SITE_URL}/direct-message/${businessSlug}`,
    onClick: () => tracker.interaction('dbm', { category_id })
  };

  return (
    <>
      <CtaDesktopWrapperStyled>
        <CtaStyled
          {...{ ...buttonProps, kind: 'primary-sm', rel: 'nofollow' }}
        >
          Message
        </CtaStyled>
      </CtaDesktopWrapperStyled>
      <CtaMobileWrapperStyled>
        <CtaStyled
          {...{ ...buttonProps, kind: 'primary', rel: 'nofollow' }}
        >
          Message
        </CtaStyled>
      </CtaMobileWrapperStyled>
    </>
  );
};

type ProfileProps = {
  business: BusinessPublicProfileModel;
  businessSlug: string;
  isStickyHeader: boolean;
  reviewsSectionRef: MutableRefObject<HTMLElement | null>;
  tracker: BusinessProfileTrackerModel;
};

const Profile: FC<ProfileProps> = ({
  business,
  businessSlug,
  isStickyHeader,
  reviewsSectionRef,
  tracker
}: ProfileProps) => {
  const { logoImgSrc } = useLogoImageSrc({ business });
  const handleClick = useCallback((e: MouseEvent) => {
    scrollToReviews(e, reviewsSectionRef, isStickyHeader);
  }, [isStickyHeader, reviewsSectionRef]);
  return (
    <SectionStyled>
      <Avatar
        imageUrl={logoImgSrc}
        lazyLoad={false} // should not lazy load images above the fold
        avatarSize={{ default: '120px' }}
      >
        <Image
          alt="Business Profile Avatar"
          src={logoImgSrc}
          fill
          priority
        />
      </Avatar>
      {
        business.featuredListing && (
          <FeaturedTagStyled size="medium" kind="primaryFilled">
            <TagWrapperStyled>
              <StarStyled />
              Featured
            </TagWrapperStyled>
          </FeaturedTagStyled>
        )
      }
      <BusinessNameHeading
        businessName={business.name}
        headingLevel="h1"
        headingStyles={{
          fontSize: '22px',
          textAlign: 'center',
          marginTop: '24px'
        }}
        iconStyles={{
          width: '16px',
          height: '16px',
          top: '1px'
        }}
        verified={business.verified}
      />
      {
        business.newToOneflare ? (
          <TagWrapperStyled>
            <NewTagStyled kind="new" size="small">New to Oneflare</NewTagStyled>
          </TagWrapperStyled>
        ) : (
          <>
            <RowWrapperStyled>
              <StarRatingStyled rating={business.feedbackAvg} />
              <Anchor href="#" onClick={handleClick}>{`(${business.feedbackCount})`}</Anchor>
            </RowWrapperStyled>
            {Boolean(business.hiredCount) && (
              <HiredCountStyled>{`Completed ${business.hiredCount} ${pluralize('job', business.hiredCount)} on Oneflare`}</HiredCountStyled>
            )}
          </>
        )
      }
      {Boolean(business.abn) && (
        <RowWrapperStyled>
          <DetailTitleStyled>ABN</DetailTitleStyled>
          <DetailCopyStyled>
            <Anchor
              href={`https://abr.business.gov.au/ABN/View/${business.abn}`}
              rel="noreferrer noopener"
              target="_blank"
              data-tooltip-content="Check this ABN"
              data-tooltip-id="profile-abn-tooltip"
              onClick={() => tracker.interaction('abn', { action: 'click_cta' })}
            >
              {formatAbnNumber(business.abn)}
            </Anchor>
          </DetailCopyStyled>
          <ReactTooltipStyled id="profile-abn-tooltip" place="right" />
        </RowWrapperStyled>
      )}
      {business.directMessagingEligible && (
      <RenderCta
        businessSlug={businessSlug}
        tracker={tracker}
        category_id={business.primaryCategory?.id}
      />
      )}
    </SectionStyled>
  );
};

export default memo(Profile);
